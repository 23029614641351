import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useFundForm, { defaultValues } from "./hooks/useFundForm";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";

const SendForm = ({ refetch, fetchData }) => {
  const { translate } = useLocales();
  const { user } = useAuth();
  const userName = user?.email;
  const [qrCode, setQrCode] = useState("");
  const [otpError, setOtpError] = useState("");
  const [loading, setLoading] = useState(false);
  const validateOtp = () => {
    if (!qrCode) {
      setOtpError("OTP is required");
      return false;
    }
    setOtpError("");
    return true;
  };

  const [openQrDialog, setOpenQrDialog] = useState(false);

  const { methods, onSubmit } = useFundForm(
    refetch,
    fetchData,
    setOpenQrDialog
  );

  const {
    watch,
    formState: { isSubmitting, errors },
  } = methods;

  const onClose = () => {
    setOpenQrDialog(false);
    setQrCode("");
    methods.reset(defaultValues);
  };

  const username = watch("username");
  const wallet = watch("wallet");
  const amount = watch("amount");
  const note = watch("note");
  const { enqueueSnackbar } = useSnackbar();

  const update = async () => {
    if (!validateOtp()) return;
    setLoading(true);
    const reqData = new FormData();
    reqData.append("otp", qrCode);
    reqData.append("username", username);
    reqData.append("wallet", wallet);
    reqData.append("amount", amount);
    reqData.append("note", note);

    try {
      const response = await axiosInstance.post(
        "api/user/fund-transfer",
        reqData
      );
      const { status, data } = response;
      if (status === 200) {
        methods.reset();
        onClose();
        enqueueSnackbar(data?.message);
        fetchData();
        refetch();
        setLoading(false);
      }
      return false;
    } catch (err) {
      setLoading(false);
      setOtpError(err.message);
      // console.error(err);
      // if (!err.errors) {
      //   setError("amount", { message: err.message });
      // }
      // Object.entries(err.errors).forEach(([k, v]) => {
      //   setError(k, { message: v });
      // });
      return false;
    }
  };

  useEffect(() => {
    methods.setValue("note", userName);
  }, [userName]);

  const { palette } = useTheme();

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "grid",
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                  mb: 2,
                }}
              >
                <RHFSelect name="wallet" label="Wallet ID">
                  <option value="ewallet">
                    {translate("userFinancial.fundTransfer.e-Wallet")}
                  </option>
                  {/* <option value="deposit_wallet">{translate("userFinancial.fundTransfer.depositWallet")}</option> */}
                </RHFSelect>
                <RHFTextField
                  type="number"
                  name="amount"
                  label={translate("userFinancial.fundTransfer.amount")}
                  onWheel={(e) => e.target.blur()}
                />
                <RHFTextField
                  name="username"
                  label={translate("userFinancial.fundTransfer.accountId")}
                />

                {/* <UsersList /> */}
                <RHFTextField
                  multiline
                  fullWidth
                  rows={1}
                  name="note"
                  // value={userName}
                  InputLabelProps={{ shrink: true }}
                  label={translate("userFinancial.fundTransfer.note")}
                />
              </Box>

              <Box sx={{ display: "flex", mt: 2 }}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                >
                  {translate("send")}
                </LoadingButton>
              </Box>
              <Dialog
                fullWidth
                maxWidth="xs"
                open={openQrDialog}
                onClose={onClose}
              >
                <DialogContent>
                  <Box sx={{ textAlign: "right" }}>
                    <Tooltip title="close">
                      <IconButton onClick={onClose}>
                        <Iconify icon="material-symbols:close" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    We have sent you an email with OTP, please check the OTP for
                    email verification
                  </Typography>
                  <Stack spacing={3} mt={1}>
                    <TextField
                      type="number"
                      error={Boolean(otpError)}
                      helperText={otpError}
                      value={qrCode}
                      onChange={(e) => {
                        setOtpError("");
                        setQrCode(e.target.value);
                      }}
                      placeholder="Enter OTP"
                    />
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      onClick={update}
                    >
                      {translate("userFinancial.requestPayout.submit")}
                    </LoadingButton>
                  </Stack>
                </DialogContent>
              </Dialog>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendForm;
